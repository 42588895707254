import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.ENV || process.env.NODE_ENV;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN || '',
    environment,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.05,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
